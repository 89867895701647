import React, { useEffect, useRef, useState } from 'react';
import NotifyController from '../utilities/Toast';
import { useNavigate, useParams } from 'react-router-dom';
import FeedbackService from '../service/FeedbackService';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import ReactJson from 'react-json-view'
import { Galleria } from 'primereact/galleria';

const GeneratedImageDetail = () => {
    const [data, setData] = useState<any>();
    const { id } = useParams();
    const galleria = useRef<any>(null);
    const galleria2 = useRef<any>(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [lengthArrayImage, setLengthArrayImage] = useState(0);

    const getImageDetailData = async () => {
        if (id) {
            try {
                const imageData = await FeedbackService.getInstance().getImageDetail(id);
                setData(imageData.data);
                setLengthArrayImage(imageData.data.url_v2.length)
            } catch (error: any) {
                NotifyController.error(error?.message);
                console.log(error);
            }
        }
    };


    const itemTemplate = (item: any) => {
        return <img src={item} style={{ width: '70%', display: 'block' }} />;
    }

    const next = () => {
        setActiveIndex(prevState => (prevState === lengthArrayImage - 1) ? 0 : prevState + 1)
    }

    const prev = () => {
        setActiveIndex((prevState) => (prevState === 0 ? 0 : prevState - 1));
    };


    const handleClickReGen = () => {
        const urlToWebApp = process.env.REACT_APP_WEB_URL + '/my-work/' + id;
        window.open(urlToWebApp, '_blank')
    }

    const closeModal = () => {
        galleria.current && galleria.current.hide();
        galleria2.current && galleria2.current.hide();
    };

    useEffect(() => {
        const handleKeyDown = (event: any) => {
            if (event.key === 'ArrowLeft') {
                prev();
            } else if (event.key === 'ArrowRight') {
                next();
            } else if (event.key === 'Escape') {
                closeModal();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [activeIndex]);

    useEffect(() => {
        getImageDetailData();
    }, [])

    return (

        <div>
            {data &&
                <div>
                    <Button onClick={handleClickReGen}>Re-Gen</Button>
                    <h4>Image Result</h4>
                    <div className="grid">
                        <Galleria ref={galleria} value={data.url_v2} activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)} numVisible={9} item={itemTemplate}
                            aria-controls=''
                            style={{
                                maxHeight: '80%',
                            }}
                            showIndicators
                            fullScreen showItemNavigators />
                        {data.url_v2.map((item: string, index: string) => {
                            return (
                                <div className="col mr-2">
                                    <Image width='150' height='200' src={item} alt="Image" onClick={() => galleria.current && galleria.current.show()} />
                                </div>
                            )
                        })}

                    </div>
                    <h4>Image Step1 Result</h4>
                    <div className="grid">
                        <Galleria ref={galleria2} value={data.url_step_1} activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)} numVisible={9} item={itemTemplate}
                            aria-controls=''
                            style={{
                                maxHeight: '80%',
                            }}
                            showIndicators
                            fullScreen showItemNavigators />
                        {data.url_step_1.map((item: string, index: string) => {
                            return (
                                <div className="col mr-2">
                                    <Image width='150' height='200' src={item} alt="Image" onClick={() => galleria2.current && galleria2.current.show()} />
                                </div>
                            )
                        })}
                    </div>
                    <h4>Init Image</h4>
                    <div>
                        <Image width='150' height='200' preview src={data.data_body.init_images} alt="Image" />
                    </div>
                    <h4>Mask</h4>
                    <div>
                        <Image width='150' height='200' preview src={data.data_body.mask} alt="Image" />
                    </div>
                    <h4>Controlnet 1: {data.data_body.alwayson_scripts?.controlnet?.args?.[0]?.model}</h4>
                    <div>
                        <Image width='150' height='200' preview src={data.data_body.alwayson_scripts?.controlnet?.args?.[0]?.input_image} alt="Image" />
                    </div>
                    <h4>Controlnet 2: {data.data_body.alwayson_scripts?.controlnet?.args?.[1]?.model}</h4>
                    <div>
                        <Image width='150' height='200' preview src={data.data_body.alwayson_scripts?.controlnet?.args?.[1]?.input_image} alt="Image" />
                    </div>
                    <h4>Controlnet 3: {data.data_body.alwayson_scripts?.controlnet?.args?.[2]?.model}</h4>
                    <div>
                        <Image width='150' height='200' preview src={data.data_body.alwayson_scripts?.controlnet?.args?.[2]?.input_image} alt="Image" />
                    </div>
                    <div className='mt-3'>
                        <h4>Full Input</h4>
                        <div>
                            <ReactJson src={data.data_body} theme={'ashes'} collapsed={true} />
                        </div>
                    </div>
                    <div className='mt-3'>
                        <h4>Full Output</h4>
                        <div>
                            <ReactJson src={JSON.parse(data.response ?? '{}')} theme={'ashes'} collapsed={true} />
                        </div>
                    </div>
                    <div className='mt-3'>
                        <h4>Info Text</h4>
                        <div>
                            {data.infoText && data.infoText?.length > 0 && data.infoText?.map((item: any, index: number) => {
                                return <ReactJson src={JSON.parse(item)} theme={'ashes'} collapsed={true} />
                            })}
                        </div>
                    </div>
                </div>
            }

        </div>

    );
};

export default GeneratedImageDetail;
